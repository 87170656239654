import "../style.scss";

import { IonAvatar, IonButton, IonIcon, IonModal, IonText } from "@ionic/react";
import { hcpAppLogger } from "@src/app/remoteLoggers";
import { ActionType } from "@src/app/store/session";
import avatarSVG from "@src/assets/images/avatar.svg";
import { getFSSProfilePicDistributionURL } from "@src/utils/fss.utils";
import { addOutline, pencilOutline } from "ionicons/icons";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { UpdateProfileImage } from "./updateImage";

const ProfileImage = ({
  userId,
  isUpdateProfileImageModalOpen,
  isProfilePictureUploaded,
}: {
  userId: string | undefined;
  isUpdateProfileImageModalOpen?: boolean;
  isProfilePictureUploaded?: boolean;
}) => {
  const [showModal, setShowModal] = useState<boolean>(isUpdateProfileImageModalOpen ?? false);
  const [profileUrl, setProfileUrl] = useState<string>("");
  const dispatch = useDispatch();
  const setUserProfilePicture = useCallback(async () => {
    try {
      const profilePicture = await getFSSProfilePicDistributionURL(userId ?? "");
      setProfileUrl(profilePicture);
      dispatch({
        type: ActionType.UPDATE_AGENT,
        data: {
          agent: { isProfilePictureUploaded: true },
        },
      });
    } catch (error) {
      hcpAppLogger({
        logType: "ERROR",
        title: `[getProfilePictureURL] could not fetch profile picture distribution url for userId: ${userId}`,
        logArea: "SERVER",
        featureName: "PROFILE",
        hcpUserId: userId,
        details: {
          error: error?.message,
          errorStack: error?.stack,
        },
      });
    }
  }, [dispatch, userId]);
  useEffect(() => {
    if (userId && showModal === false) {
      setUserProfilePicture();
    }
  }, [userId, showModal, setUserProfilePicture]);
  const handleShowModal = () => {
    setShowModal(true);
  };

  const onClose = () => {
    setShowModal(false);
  };

  return (
    <Fragment>
      <div className="profilePic" role={"button"} tabIndex={0} onClick={handleShowModal}>
        <IonAvatar
          style={{
            width: 80,
            height: 80,
            backgroundColor: profileUrl?.length
              ? "var(--ion-color-light-shade)"
              : "var(--ion-color-light)",
          }}
        >
          {isProfilePictureUploaded ? (
            <img src={profileUrl} alt="profile" />
          ) : (
            <img src={avatarSVG} alt="no profile avatar" />
          )}
        </IonAvatar>
      </div>
      <IonButton fill="outline" shape="round" onClick={handleShowModal} strong>
        {isProfilePictureUploaded ? (
          <>
            <IonIcon slot="start" icon={pencilOutline}></IonIcon>
            <IonText>Edit photo</IonText>
          </>
        ) : (
          <>
            <IonIcon slot="start" icon={addOutline}></IonIcon>
            <IonText>Upload Photo</IonText>
          </>
        )}
      </IonButton>
      <IonModal isOpen={showModal} onDidDismiss={onClose}>
        <UpdateProfileImage profileUrl={profileUrl} onClose={onClose} />
      </IonModal>
    </Fragment>
  );
};

export { ProfileImage };
